<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios'
import Swal from 'sweetalert2';

import {
  required,
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Transaction Adjustment",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Transaction Adjustment",
      items: [
        {
          text: "Transaction",
          href: "/"
        },
        {
          text: "Adjustment",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "transaction.customerName", sortable: true, label: "Customer Name" },
        {
          key: "transaction.transactionData.changes.transactionDate",
          sortable: true,
          label: "Transaction Date",
        },
        {
          key: "transaction.transactionData.changes.transactionAmount",
          sortable: true,
          label: "Transaction Amount (GHS)",
        },
        {
          key: "transaction.transactionData.changes.paymentTypeId",
          sortable: true,
          label: "Payment Type",
        },
        { key: "transaction.transactionType" },
        { key: "transaction.transactionPaymentType", label: "Individaul / Group" },
        { key: "transaction.notified", label: "Notified" },
      ],
      form: {
        amount: "",
        reason: "",
      },
      adjustments: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    form: {
      amount: { required },
      reason: { required },
    },
  },
  computed:{
     /**
     * Total no. of records
     */
    rows() {
      return this.adjustments.length;
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    
    // post adjustment
   async postAdjustment() {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to submit this adjustment?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.formSubmit();
        try {
          const response = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/adjust-transaction-request/create`,
            {
              transactionId: this.$route.params.id, 
              agentId: localStorage.getItem("id"),
              amount: parseFloat(this.form.amount),
              reason: this.form.reason
            }
          );
          this.$toast.success(response.data.message);
          location.reload();  // Refresh the page
        } catch (error) {
          this.$toast.error(error.response.data.message);
        }
      } else {
        Swal.fire({
          title: 'Cancelled',
          text: 'The adjustment was not submitted.',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        });
      }
    },
     async getAdjustments() {
      try {
        const response = await axios.get(
          `${
            process.env.VUE_APP_BASE_URL
          }/adjust-transaction-request/agent/${localStorage.getItem("id")}`
        );
        if (response.status === 200 || response.status === 201) {
        console.log(response.data)
          this.adjustments = response.data
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
  },
  mounted(){
    this.getAdjustments()
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Adjust Transactions</h4>
            <p
              class="card-title-desc"
            >Adjust transactions here, an admin has to approve a transaction that has been reversed.</p>
            <form class="needs-validation" @submit.prevent="postAdjustment()">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom01">Amount</label>
                    <input
                      id="validationCustom01"
                 
                      v-model="form.amount"
                      type="text"
                      class="form-control"
                      placeholder="Amount"
                      :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
                    />
                    <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                      <span v-if="!$v.form.amount.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Reason</label>
                    <textarea
                      id="validationCustom02"
                      v-model="form.reason"
                      type="text"
                      class="form-control"
                      placeholder="Reason for Adjustment ?"
                      :class="{ 'is-invalid': submitted && $v.form.reason.$error }"
                    />
                    <div v-if="submitted && $v.form.reason.$error" class="invalid-feedback">
                      <span v-if="!$v.form.reason.required">This value is required.</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Submit form</button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Transactions Adjusted</h4>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="adjustments"
                :fields="fields"
                responsive="sm"
                stacked="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template
                  v-slot:cell(transaction.transactionData.changes.paymentTypeId)="row"
                >
                  <div
                    v-if="row.value == 1"
                    class="badge badge-soft-success font-size-12"
                  >
                    Cash Payment
                  </div>
                  <div
                    v-if="row.value == 2"
                    class="badge badge-soft-warning font-size-12"
                  >
                    Momo Payment
                  </div>
                </template>
                <template v-slot:cell(transaction.transactionType)="row">
                  <div v-if="row.value == 'LoanPayment'" class="badge badge-success font-size-12">Loan Payment</div>
                  <div v-if="row.value == 'SavingsDeposit'" class="badge badge-warning font-size-12">
                    Cash Collateral
                  </div>
                  <div v-if="row.value == 'Withdrawal'" class="badge badge-danger font-size-12">Withdrawal</div>
                </template>
                <template v-slot:cell(transaction.notified)="row">
                  <div
                    v-if="row.value == true"
                    class="badge badge-soft-success font-size-12"
                  >
                    Message Sent
                  </div>
                  <div
                    v-if="row.value == false"
                    class="badge badge-soft-danger font-size-12"
                  >
                    Failed
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </Layout>
</template>